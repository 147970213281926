import * as seasonActions from 'redux/actions/season'
import {RESET_STATE} from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case seasonActions.FAILED_PERSIST_SEASON:
    case seasonActions.FAILED_GET_SEASON:
      return {
        ...state,
        form: {
          ...state.form,
          fetching: false,
          sending: false,
          deleting: false,
        }
      }
    case seasonActions.RECEIVE_PERSIST_SEASON:
    case seasonActions.RECEIVE_GET_SEASON:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
          sending: false,
        }
      }
    case seasonActions.RECEIVE_GET_SEASONS:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RESET_STATE:
      if (action.state === 'season') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
