import * as roundActions from 'redux/actions/round'
import { RESET_STATE } from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case roundActions.FAILED_PERSIST_ROUND:
    case roundActions.FAILED_GET_ROUND:
      return {
        ...state,
        form: {
          ...state.form,
          fetching: false,
          sending: false,
          deleting: false,
        }
      }
    case roundActions.RECEIVE_PERSIST_ROUND:
    case roundActions.RECEIVE_GET_ROUND:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
          sending: false,
        }
      }
    case roundActions.RECEIVE_GET_ROUNDS:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RESET_STATE:
      if (action.state === 'round') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
