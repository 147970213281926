import {
  RECEIVE_GET_ROLE,
  RECEIVE_GET_ROLES,
  RECEIVE_PERSIST_ROLE,
} from 'redux/actions/role'
import {RESET_STATE} from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PERSIST_ROLE:
    case RECEIVE_GET_ROLE:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
        }
      }
    case RECEIVE_GET_ROLES:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RESET_STATE:
      if (action.state === 'role') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
