import { combineReducers } from 'redux'
import { isServer } from 'config/helpers'
import { routerReducer as router } from 'react-router-redux'
import competition from './competition'
import game from './game'
import phase from './phase'
import role from './role'
import round from './round'
import season from './season'
import sport from './sport'
import user from './user'

const base = {
  preloaded: () => !isServer,
  router,
}

const appReducer = combineReducers({
  ...base,
  competition,
  game,
  phase,
  role,
  round,
  season,
  sport,
  user,
})

export default function (state = {}, action = {}) {
  return appReducer(state, action)
}
