import {
  FAILED_AUTOCOMPLETE_TAGS,
  FAILED_GET_GAME,
  FAILED_GET_GAMES,
  RECEIVE_AUTOCOMPLETE_TAGS,
  RECEIVE_GET_GAME,
  RECEIVE_GET_GAME_CLOCK,
  RECEIVE_GET_GAME_EVENTS,
  RECEIVE_GET_GAME_SCORE,
  RECEIVE_GET_GAMES,
  RECEIVE_PERSIST_GAME_EVENT,
  RECEIVE_TOGGLE_GAME_CLOCK,
} from 'redux/actions/game'
import {RESET_STATE} from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
  clock: {
    data: [],
    error: '',
    fetching: true,
  },
  events: {
    data: [],
    error: '',
    fetching: true,
  },
  score:  {
    data: [],
    error: '',
    fetching: true,
  },
  tags: {
    data: [],
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FAILED_GET_GAME:
      return {
        ...state,
        form: {
          ...state.form,
          fetching: false,
          sending: false,
          deleting: false,
        }
      }
    case RECEIVE_AUTOCOMPLETE_TAGS:
      return {
        ...state,
        tags: {
          data: action.data,
        },
      }
    case RECEIVE_GET_GAME:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
          sending: false,
        }
      }
    case RECEIVE_GET_GAME_CLOCK:
    case RECEIVE_TOGGLE_GAME_CLOCK:
      return {
        ...state,
        clock: {
          data: action.data,
          fetching: false,
          sending: false,
        }
      }
    case RECEIVE_GET_GAME_EVENTS:
      return {
        ...state,
        events: {
          data: action.data
            ?.sort((a, b) => b.timestamp - a.timestamp),
          fetching: false,
          sending: false,
        }
      }
    case RECEIVE_GET_GAME_SCORE:
      return {
        ...state,
        score: {
          data: action.data,
          fetching: false,
          sending: false,
        }
      }
    case RECEIVE_GET_GAMES:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RECEIVE_PERSIST_GAME_EVENT:
      return state
    case RESET_STATE:
      if (action.state === 'game') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
