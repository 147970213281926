import { fork } from 'redux-saga/effects'

// Import sagas
import competition from './competition'
import game from './game'
import phase from './phase'
import role from './role'
import round from './round'
import season from './season'
import sport from './sport'
import user from './user'

// Export root saga
export default function* root () {
  yield fork(competition)
  yield fork(game)
  yield fork(phase)
  yield fork(role)
  yield fork(round)
  yield fork(season)
  yield fork(sport)
  yield fork(user)
}
