import { call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import api from 'services/api'
import {
  FAILED_GET_COMPETITION,
  FAILED_GET_COMPETITIONS,
  RECEIVE_GET_COMPETITION,
  RECEIVE_GET_COMPETITIONS,
  REQUEST_GET_COMPETITION,
  REQUEST_GET_COMPETITIONS,
} from 'redux/actions/competition'

function* getCompetition(action) {
  try {
    const { data } = yield call(api.get, `/competition/${action.id}`);

    yield put({ type: RECEIVE_GET_COMPETITION, data })
  } catch (error) {
    yield put({ type: FAILED_GET_COMPETITION })
  }
}

function* getCompetitions() {
  try {
    const { data } = yield call(api.get, `/competition`);

    yield put({ type: RECEIVE_GET_COMPETITIONS, data })
  } catch (error) {
    yield put({ type: FAILED_GET_COMPETITIONS })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_COMPETITION, getCompetition)
  yield takeLatest(REQUEST_GET_COMPETITIONS, getCompetitions)
}
