export const host = typeof window !== 'undefined'
  ? window.location.hostname
  : null

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export function capitalise(string = '') {
  return string && string[0].toUpperCase() + string.slice(1)
}

export function join(delimiter = ' ', ...args) {
  return args.join(delimiter).trim()
}

export function sluggify(string = '') {
  return string && [...string.toLowerCase()]
    .filter((char) => /[\w\d\s-/~]/.test(char))
    .join('')
    .replace(/[\s-/~]/g, '-')
}

export function secondsToTime(time) {
    const secs = time > 0 ? time % 60 : 0
    const mins = time > 0 ? (time - (time % 60)) / 60 : 0

    return `${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`
}

export function timeToSeconds(time)  {
    const [minutes, seconds] = time.split(':')

    return (parseInt(minutes)*60) + parseInt(seconds)
}

export function timeComponentFormatted(part) {
    const time = parseInt(part, 10)
    return `${time > 9 ? time : '0' + time}`
}

export function toQueryString(params) {
  return Object
    .keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
}

export function validateEmailAddress(email = '') {
  const regex = /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}[.]{1}){1,125}[a-zA-Z0-9]{2,63}$/

  return email && regex.test(email)
}
