import * as phaseActions from 'redux/actions/phase'
import {RESET_STATE} from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case phaseActions.FAILED_PERSIST_PHASE:
    case phaseActions.FAILED_GET_PHASE:
      return {
        ...state,
        form: {
          ...state.form,
          fetching: false,
          sending: false,
          deleting: false,
        }
      }
    case phaseActions.RECEIVE_PERSIST_PHASE:
    case phaseActions.RECEIVE_GET_PHASE:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
          sending: false,
        }
      }
    case phaseActions.RECEIVE_GET_PHASES:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RESET_STATE:
      if (action.state === 'phase') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
