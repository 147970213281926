export const RECEIVE_DELETE_ROLE = 'RECEIVE_DELETE_ROLE'
export const RECEIVE_GET_ROLE = 'RECEIVE_GET_ROLE'
export const RECEIVE_GET_ROLES = 'RECEIVE_GET_ROLES'
export const RECEIVE_PERSIST_ROLE = 'RECEIVE_PERSIST_ROLE'
export const REQUEST_DELETE_ROLE = 'REQUEST_DELETE_ROLE'
export const REQUEST_GET_ROLE = 'REQUEST_GET_ROLE'
export const REQUEST_GET_ROLES = 'REQUEST_GET_ROLES'
export const REQUEST_PERSIST_ROLE = 'REQUEST_PERSIST_ROLE'
export const FAILED_DELETE_ROLE = 'FAILED_DELETE_ROLE'
export const FAILED_GET_ROLE = 'FAILED_GET_ROLE'
export const FAILED_GET_ROLES = 'FAILED_GET_ROLES'
export const FAILED_PERSIST_ROLE = 'FAILED_PERSIST_ROLE'
