export const ROLE_ADMINISTRATOR = 'administrator'

export const ROLE_API_CLIENT = 'api_client'

export const ROLE_COMMENTATOR = 'commentator'

export const supportedRoles = [
  ROLE_ADMINISTRATOR,
  ROLE_API_CLIENT,
  ROLE_COMMENTATOR,
]
