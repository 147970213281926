import { call, delay, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { apiV2 } from 'services/api'
import * as roundActions from 'redux/actions/round'

function* deleteRound(action) {
  try {
    const data = yield call(apiV2.delete, `/round/${action.id}`)

    yield put({ type: roundActions.RECEIVE_DELETE_ROUND })
  } catch (error) {
    yield put({ type: roundActions.FAILED_DELETE_ROUND })
  }
}

function* getRound(action) {
  try {
    const data = yield call(apiV2.get, `/round/${action.id}`)

    yield put({ type: roundActions.RECEIVE_GET_ROUND, data })
  } catch (error) {
    yield put({ type: roundActions.FAILED_GET_ROUND })
  }
}

function* getRounds(action) {
  try {
    const { params } = action
    const queryString = Object.keys(params)
      .map((param) => `${param}=${params[param]}`)
      .join('&')

    const endpoint = params?.phase
      ? `/round?${queryString}`
      : `/round`
    const { data } = yield call(apiV2.get, endpoint)

    yield put({ type: roundActions.RECEIVE_GET_ROUNDS, data })
  } catch (error) {
    console.error(error)
    yield put({ type: roundActions.FAILED_GET_ROUNDS })
  }
}

function* persistRound(action) {
  try {
    // Debounce
    yield delay(500)

    const data = action?.id
      ? yield call(apiV2.put, `/round/${action.id}`, action.data)
      : yield call(apiV2.post, `/round`, action.data)

    yield put({ type: roundActions.RECEIVE_PERSIST_ROUND, data })
    yield put({
      type: roundActions.REQUEST_GET_ROUNDS,
      params: {
        ...(action.data.phase_id ? { phase: action.data.phase_id } : {})
      },
    })
  } catch (error) {
    yield put({ type: roundActions.FAILED_PERSIST_ROUND })
  }
}

export default function* root() {
  yield takeLatest(roundActions.REQUEST_DELETE_ROUND, deleteRound)
  yield takeLatest(roundActions.REQUEST_GET_ROUND, getRound)
  yield takeLatest(roundActions.REQUEST_GET_ROUNDS, getRounds)
  yield takeLatest(roundActions.REQUEST_PERSIST_ROUND, persistRound)
}
