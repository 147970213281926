const ROUTE_SPORT = '/sport/:sportSlug'
const ROUTE_SPORTS = '/sports'
const ROUTE_COMPETITION = `${ROUTE_SPORT}/competition/:competitionId?`
const ROUTE_COMPETITIONS = `${ROUTE_SPORT}/competitions`
const ROUTE_LOGIN = '/login'
const ROUTE_MATCH = `${ROUTE_SPORT}/match/:matchId?`
const ROUTE_MATCH_LINEUP = `${ROUTE_MATCH}/lineup`
const ROUTE_MATCH_LOGGER = `${ROUTE_MATCH}/logger`
const ROUTE_MATCHES = `${ROUTE_COMPETITION}/matches`
const ROUTE_SEASON = `${ROUTE_COMPETITION}/season/:seasonId?`
const ROUTE_SEASONS = `${ROUTE_COMPETITION}/seasons`
const ROUTE_USER = '/user/:userId?'
const ROUTE_USERS = '/users'

const makeRoute = (route, params) => {
  // Object.keys(params)
}

export {
  makeRoute,
  ROUTE_COMPETITION,
  ROUTE_COMPETITIONS,
  ROUTE_LOGIN,
  ROUTE_MATCH,
  ROUTE_MATCH_LINEUP,
  ROUTE_MATCH_LOGGER,
  ROUTE_MATCHES,
  ROUTE_SEASON,
  ROUTE_SEASONS,
  ROUTE_SPORT,
  ROUTE_SPORTS,
  ROUTE_USER,
  ROUTE_USERS,
}
