export const FAILED_DELETE_ROUND = 'FAILED_DELETE_ROUND'
export const FAILED_GET_ROUND = 'FAILED_GET_ROUND'
export const FAILED_GET_ROUNDS = 'FAILED_GET_ROUNDS'
export const FAILED_PERSIST_ROUND = 'FAILED_PERSIST_ROUND'
export const RECEIVE_DELETE_ROUND = 'RECEIVE_DELETE_ROUND'
export const RECEIVE_GET_ROUND = 'RECEIVE_GET_ROUND'
export const RECEIVE_GET_ROUNDS = 'RECEIVE_GET_ROUNDS'
export const RECEIVE_PERSIST_ROUND = 'RECEIVE_PERSIST_ROUND'
export const REQUEST_DELETE_ROUND = 'REQUEST_DELETE_ROUND'
export const REQUEST_GET_ROUND = 'REQUEST_GET_ROUND'
export const REQUEST_GET_ROUNDS = 'REQUEST_GET_ROUNDS'
export const REQUEST_PERSIST_ROUND = 'REQUEST_PERSIST_ROUND'
