import { call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import api from 'services/api'

import * as userActions from 'redux/actions/user'

function* authStatus() {
  try {
    const { id, meta, role } = yield call(api.get, `/auth/status`);

    yield put({ type: userActions.RECEIVE_USER_AUTH_STATUS, id, meta, role })
  } catch (error) {
    yield put({ type: userActions.FAILED_USER_AUTH_STATUS })
  }
}

function* deleteUser(action) {
  try {
    yield call(api.delete, `/user/${action.id}`)
    yield put({ type: userActions.RECEIVE_DELETE_USER })

    //window.location.href = "/users"
  } catch (error) {
    yield put({ type: userActions.FAILED_DELETE_USER })
  }
}

function* getUser(action) {
  try {
    const data = yield call(api.get, `/user/${action.id}`)

    yield put({ type: userActions.RECEIVE_GET_USER, data })
  } catch(error) {
    yield put({ type: userActions.FAILED_GET_USER, error })
  }
}

function* getUsers() {
  try {
    const { data } = yield call(api.get, '/user')

    yield put({ type: userActions.RECEIVE_GET_USERS, data })
  } catch(error) {
    yield put({ type: userActions.FAILED_GET_USERS, error })
  }
}

function* login(action) {
  try {
    yield call(api.get, `${process.env.RAZZLE_API_HOST}/sanctum/csrf-cookie`)
    yield call(api.post, '/login', action)

    yield put({ type: userActions.RECEIVE_LOGIN_USER })
    yield put(push('/'))
  } catch(error) {
    yield put({ type: userActions.FAILED_LOGIN_USER, error })
  }
}

function* logout() {
  try {
    yield call(api.post, '/logout')

    yield put({ type: userActions.RECEIVE_LOGOUT_USER })
    window.location.href = '/login';
  } catch(error) {
    yield put({ type: userActions.FAILED_LOGOUT_USER, error })
  }
}

function* persistUser(action) {
  try {
    const data = action?.id
      ? yield call(api.put, `/user/${action.id}`, action.data)
      : yield call(api.post, `/user`, action.data)

    yield put({ type: userActions.RECEIVE_PERSIST_USER, data })
  } catch(error) {
    yield put({ type: userActions.FAILED_GET_USER, error })
  }
}

export default function* root() {
  yield takeLatest(userActions.REQUEST_DELETE_USER, deleteUser)
  yield takeLatest(userActions.REQUEST_GET_USER, getUser)
  yield takeLatest(userActions.REQUEST_GET_USERS, getUsers)
  yield takeLatest(userActions.REQUEST_LOGIN_USER, login)
  yield takeLatest(userActions.REQUEST_LOGOUT_USER, logout)
  yield takeLatest(userActions.REQUEST_PERSIST_USER, persistUser)
  yield takeLatest(userActions.REQUEST_USER_AUTH_STATUS, authStatus)
}
