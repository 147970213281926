export const FAILED_AUTOCOMPLETE_TAGS = 'FAILED_AUTOCOMPLETE_TAGS'
export const FAILED_DELETE_GAME_EVENT = 'FAILED_PERSIST_GAME_EVENT'
export const FAILED_GET_GAME = 'FAILED_GET_GAME'
export const FAILED_GET_GAME_CLOCK = 'FAILED_GET_GAME_CLOCK'
export const FAILED_GET_GAME_EVENTS = 'FAILED_GET_GAME_EVENTS'
export const FAILED_GET_GAME_SCORE = 'FAILED_GET_GAME_SCORE'
export const FAILED_GET_GAMES = 'FAILED_GET_GAMES'
export const FAILED_PERSIST_GAME = 'FAILED_PERSIST_GAME'
export const FAILED_PERSIST_GAME_EVENT = 'FAILED_PERSIST_GAME_EVENT'
export const FAILED_PERSIST_GAME_ROUND = 'FAILED_PERSIST_GAME_ROUND'
export const FAILED_TOGGLE_GAME_CLOCK = 'FAILED_TOGGLE_GAME_CLOCK'
export const FAILED_UPDATE_GAME_PERIOD = 'FAILED_UPDATE_GAME_PERIOD'
export const RECEIVE_AUTOCOMPLETE_TAGS = 'RECEIVE_AUTOCOMPLETE_TAGS'
export const RECEIVE_DELETE_GAME_EVENT = 'RECEIVE_DELETE_GAME_EVENT'
export const RECEIVE_GET_GAME = 'RECEIVE_GET_GAME'
export const RECEIVE_GET_GAME_CLOCK = 'RECEIVE_GET_GAME_CLOCK'
export const RECEIVE_GET_GAME_EVENTS = 'RECEIVE_GET_GAME_EVENTS'
export const RECEIVE_GET_GAME_SCORE = 'RECEIVE_GET_GAME_SCORE'
export const RECEIVE_GET_GAMES = 'RECEIVE_GET_GAMES'
export const RECEIVE_PERSIST_GAME = 'RECEIVE_PERSIST_GAME'
export const RECEIVE_PERSIST_GAME_EVENT = 'RECEIVE_PERSIST_GAME_EVENT'
export const RECEIVE_PERSIST_GAME_ROUND = 'RECEIVE_PERSIST_GAME_ROUND'
export const RECEIVE_TOGGLE_GAME_CLOCK = 'RECEIVE_TOGGLE_GAME_CLOCK'
export const RECEIVE_UPDATE_GAME_PERIOD = 'RECEIVE_UPDATE_GAME_PERIOD'
export const REQUEST_AUTOCOMPLETE_TAGS = 'REQUEST_AUTOCOMPLETE_TAGS'
export const REQUEST_DELETE_GAME_EVENT = 'REQUEST_DELETE_GAME_EVENT'
export const REQUEST_GET_GAME = 'REQUEST_GET_GAME'
export const REQUEST_GET_GAME_CLOCK = 'REQUEST_GET_GAME_CLOCK'
export const REQUEST_GET_GAME_EVENTS = 'REQUEST_GET_GAME_EVENTS'
export const REQUEST_GET_GAME_SCORE = 'REQUEST_GET_GAME_SCORE'
export const REQUEST_GET_GAMES = 'REQUEST_GET_GAMES'
export const REQUEST_PERSIST_GAME = 'REQUEST_PERSIST_GAME'
export const REQUEST_PERSIST_GAME_EVENT = 'REQUEST_PERSIST_GAME_EVENT'
export const REQUEST_PERSIST_GAME_ROUND = 'REQUEST_PERSIST_GAME_ROUND'
export const REQUEST_TOGGLE_GAME_CLOCK = 'REQUEST_TOGGLE_GAME_CLOCK'
export const REQUEST_UPDATE_GAME_PERIOD = 'REQUEST_UPDATE_GAME_PERIOD'
