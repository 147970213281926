export const FAILED_DELETE_SEASON = 'FAILED_DELETE_SEASON'
export const FAILED_GET_SEASON = 'FAILED_GET_SEASON'
export const FAILED_GET_SEASONS = 'FAILED_GET_SEASONS'
export const FAILED_PERSIST_SEASON = 'FAILED_PERSIST_SEASON'
export const RECEIVE_DELETE_SEASON = 'RECEIVE_DELETE_SEASON'
export const RECEIVE_GET_SEASON = 'RECEIVE_GET_SEASON'
export const RECEIVE_GET_SEASONS = 'RECEIVE_GET_SEASONS'
export const RECEIVE_PERSIST_SEASON = 'RECEIVE_PERSIST_SEASON'
export const REQUEST_DELETE_SEASON = 'REQUEST_DELETE_SEASON'
export const REQUEST_GET_SEASON = 'REQUEST_GET_SEASON'
export const REQUEST_GET_SEASONS = 'REQUEST_GET_SEASONS'
export const REQUEST_PERSIST_SEASON = 'REQUEST_PERSIST_SEASON'
