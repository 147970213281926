import React, { useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Redirect, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { ROLE_ADMINISTRATOR } from 'config/user'
import { isServer } from 'config/helpers'
import styles from './Routes.module.scss'
import * as routes from 'config/routes'

// Import components
import Loader from 'ui/components/Loader'
import PrivateRoute from 'ui/components/PrivateRoute'
import PublicRoute from 'ui/components/PublicRoute'
// import NotFound from 'ui/pages/Error/NotFound'

const options = {
  fallback: <Loader />
}

// Loadable
const Login = loadable(() => import('ui/containers/Authentication/Login'), options)
const Dashboard = loadable(() => import('ui/containers/Dashboard'), options)
const Logger = loadable(() => import('ui/containers/Logger'), options)
const LineUp = loadable(() => import('ui/containers/LineUp'), options)
const MatchCenter = loadable(() => import('ui/containers/MatchCenter'), options)
const SeasonForm = loadable(() => import('ui/containers/Season/Form'), options)
const SeasonOverview = loadable(() => import('ui/containers/Season/Overview'), options)
const UserForm = loadable(() => import('ui/containers/User/Form'), options)
const UserOverview = loadable(() => import('ui/containers/User/Overview'), options)

export default ({ authenticated, checked, role, userAuthStatus, ...props }) => {
  useEffect(() => {
    userAuthStatus()
  }, [props.location])

  // Server can skip auth check (should also move this check to the private route component)
  if (typeof window !== 'undefined' && !checked) {
    return <Loader />
  }

  return (
    <Route
      render={({ location }) => {
        // Strange bug, for some reason Redux PUSH action is in location after navigating to new page
        const currentLocation = location.action ? location.location : location

        return (
          <TransitionGroup component={null}>
            <CSSTransition
              key={currentLocation.key}
              classNames={{
                enter: styles.Enter,
                enterActive: styles.EnterActive,
                exit: styles.Exit,
                exitActive: styles.ExitActive,
              }}
              timeout={300}
            >
              <Switch location={currentLocation}>
                <PublicRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_LOGIN}
                  render={() => <Login />}
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path="/"
                  render={() => {
                    if (isServer) {
                      return null
                    }

                    return <Redirect to="/sport/fieldhockey" />
                  }}
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_SPORT}
                  render={({ match }) =>
                    <Dashboard
                      sportSlug={match.params.sportSlug}
                    />
                  }
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_MATCHES}
                  render={({ match }) =>
                    <MatchCenter
                      competitionId={match.params.competitionId}
                      sportSlug={match.params.sportSlug}
                    />
                  }
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_MATCH_LINEUP}
                  render={({ match }) =>
                    <LineUp
                      id={match.params.matchId}
                      sportSlug={match.params.sportSlug}
                    />
                  }
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_MATCH_LOGGER}
                  render={({ match }) =>
                    <Logger
                      id={match.params.matchId}
                      sportSlug={match.params.sportSlug}
                    />
                  }
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_SEASONS}
                  render={({ match }) =>
                    <SeasonOverview
                      competitionId={match.params.competitionId}
                      sportSlug={match.params.sportSlug}
                    />
                  }
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_SEASON}
                  render={({ match }) =>
                    <SeasonForm
                      competitionId={match.params.competitionId}
                      id={match.params.seasonId}
                      sportSlug={match.params.sportSlug}
                    />
                  }
                  strict
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_USERS}
                  render={() => <UserOverview />}
                  strict
                  userRole={role}
                />

                <PrivateRoute
                  authenticated={authenticated}
                  exact
                  path={routes.ROUTE_USER}
                  render={({ match }) => <UserForm id={match.params.userId} />}
                  strict
                  userRole={role}
                />

                {/*<Route component={NotFound} />*/}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )
      }}
    />
  )
}
