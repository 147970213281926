export const FAILED_DELETE_PHASE = 'FAILED_DELETE_PHASE'
export const FAILED_GET_PHASE = 'FAILED_GET_PHASE'
export const FAILED_GET_PHASES = 'FAILED_GET_PHASES'
export const FAILED_PERSIST_PHASE = 'FAILED_PERSIST_PHASE'
export const RECEIVE_DELETE_PHASE = 'RECEIVE_DELETE_PHASE'
export const RECEIVE_GET_PHASE = 'RECEIVE_GET_PHASE'
export const RECEIVE_GET_PHASES = 'RECEIVE_GET_PHASES'
export const RECEIVE_PERSIST_PHASE = 'RECEIVE_PERSIST_PHASE'
export const REQUEST_DELETE_PHASE = 'REQUEST_DELETE_PHASE'
export const REQUEST_GET_PHASE = 'REQUEST_GET_PHASE'
export const REQUEST_GET_PHASES = 'REQUEST_GET_PHASES'
export const REQUEST_PERSIST_PHASE = 'REQUEST_PERSIST_PHASE'
