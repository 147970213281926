import {call, takeLatest, put} from 'redux-saga/effects'
import api from 'services/api'

import {
  FAILED_GET_ROLES,
  RECEIVE_GET_ROLES,
  REQUEST_GET_ROLES,
} from 'redux/actions/role'

function* getRoles(action) {
  try {
    const { data } = yield call(api.get, '/role')

    yield put({ type: RECEIVE_GET_ROLES, data })
  } catch(error) {
    yield put({ type: FAILED_GET_ROLES, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_ROLES, getRoles)
}
