import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import styles from './PrivateRoute.module.scss'

type PrivateRouteProps = {
  authenticated: boolean
  loginUrl: string
  roles: null | string[]
  ssrEnabled: boolean
  userRole: string
}

function checkRole(userRole, roles) {
  return roles.length === 0 || roles.includes(userRole)
}

export default ({
  authenticated = false,
  loginUrl = '/login',
  roles = [],
  ssrEnabled = false,
  userRole = null,
  ...rest
}: PrivateRouteProps) => {
  const isBrowser = typeof window !== 'undefined'
  const browserUnauthenticated = isBrowser && !authenticated
  const browserAuthenticated = isBrowser && authenticated && checkRole(userRole, roles)

  if (!ssrEnabled && !isBrowser) {
    return null
  }

  if (browserUnauthenticated) {
    return <Redirect to={loginUrl} />
  }

  if (browserAuthenticated) {
    return <Route {...rest} />
  }

  return <h1 className={styles.Unauthorized}>Unauthorized... 😱😱😱</h1>
}
