import { call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import api, { apiV2 } from 'services/api'
import * as seasonActions from 'redux/actions/season'

function* deleteSeason(action) {
  try {
    const data = yield call(api.delete, `/season/${action.id}`)

    yield put({ type: seasonActions.RECEIVE_DELETE_SEASON })
  } catch (error) {
    yield put({ type: seasonActions.FAILED_DELETE_SEASON })
  }
}

function* getSeason(action) {
  try {
    const data = yield call(apiV2.get, `/season/${action.id}`)

    yield put({ type: seasonActions.RECEIVE_GET_SEASON, data })
  } catch (error) {
    yield put({ type: seasonActions.FAILED_GET_SEASON })
  }
}

function* getSeasons(action) {
  try {
    const { competitionId, fromDate, toDate } = action
    const queryParams = {
      ...(competitionId ? { competition: competitionId } : {}),
      ...(fromDate ? { fromDate: `${fromDate} 00:00:00` } : {}),
      ...(toDate ? { toDate: `${toDate} 00:00:00` } : {}),
    }
    const queryString = Object.keys(queryParams)
      .map((param) => `${param}=${queryParams[param]}`)
      .join('&')

    const endpoint = action?.competitionId
      ? `/season?${queryString}`
      : `/season`
    const { data } = yield call(api.get, endpoint)

    yield put({ type: seasonActions.RECEIVE_GET_SEASONS, data })
  } catch (error) {
    yield put({ type: seasonActions.FAILED_GET_SEASONS })
  }
}

function* persistSeason(action) {
  try {
    const data = action?.id
      ? yield call(api.put, `/season/${action.id}`, action.data)
      : yield call(api.post, `/season`, action.data)

    yield put({ type: seasonActions.RECEIVE_PERSIST_SEASON, data })
  } catch (error) {
    yield put({ type: seasonActions.FAILED_PERSIST_SEASON })
  }
}

export default function* root() {
  yield takeLatest(seasonActions.REQUEST_DELETE_SEASON, deleteSeason)
  yield takeLatest(seasonActions.REQUEST_GET_SEASON, getSeason)
  yield takeLatest(seasonActions.REQUEST_GET_SEASONS, getSeasons)
  yield takeLatest(seasonActions.REQUEST_PERSIST_SEASON, persistSeason)
}
