import { call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { apiV2 } from 'services/api'
import * as phaseActions from 'redux/actions/phase'

function* deletePhase(action) {
  try {
    const data = yield call(apiV2.delete, `/phase/${action.id}`)

    yield put({ type: phaseActions.RECEIVE_DELETE_PHASE })
  } catch (error) {
    yield put({ type: phaseActions.FAILED_DELETE_PHASE })
  }
}

function* getPhase(action) {
  try {
    const data = yield call(apiV2.get, `/phase/${action.id}`)

    yield put({ type: phaseActions.RECEIVE_GET_PHASE, data })
  } catch (error) {
    yield put({ type: phaseActions.FAILED_GET_PHASE })
  }
}

function* getPhases(action) {
  try {
    const { season, fromDate, toDate } = action.params
    const queryParams = {
      ...(season ? { season } : {}),
      ...(fromDate ? { fromDate: `${fromDate} 00:00:00` } : {}),
      ...(toDate ? { toDate: `${toDate} 00:00:00` } : {}),
    }
    const queryString = Object.keys(queryParams)
      .map((param) => `${param}=${queryParams[param]}`)
      .join('&')
    const endpoint = queryString
      ? `/phase?${queryString}`
      : `/phase`
    const { data } = yield call(apiV2.get, endpoint)

    yield put({ type: phaseActions.RECEIVE_GET_PHASES, data })
  } catch (error) {
    yield put({ type: phaseActions.FAILED_GET_PHASES })
  }
}

function* persistPhase(action) {
  try {
    const data = action?.id
      ? yield call(apiV2.put, `/phase/${action.id}`, action.data)
      : yield call(apiV2.post, `/phase`, action.data)

    yield put({ type: phaseActions.RECEIVE_PERSIST_PHASE, data })
  } catch (error) {
    yield put({ type: phaseActions.FAILED_PERSIST_PHASE })
  }
}

export default function* root() {
  yield takeLatest(phaseActions.REQUEST_DELETE_PHASE, deletePhase)
  yield takeLatest(phaseActions.REQUEST_GET_PHASE, getPhase)
  yield takeLatest(phaseActions.REQUEST_GET_PHASES, getPhases)
  yield takeLatest(phaseActions.REQUEST_PERSIST_PHASE, persistPhase)
}
