import React from 'react'
import { Route, Redirect } from 'react-router-dom'

type PublicRouteProps = {
  authenticated: boolean
  redirectUrl: string
}

export default ({ authenticated = false, redirectUrl = '/', ...rest }: PublicRouteProps) => {
  const isBrowser = typeof window !== 'undefined'
  const browserUnauthenticated = isBrowser && !authenticated
  const browserAuthenticated = isBrowser && authenticated

  if (browserAuthenticated) {
    return <Redirect to={redirectUrl} />
  }

  if (browserUnauthenticated) {
    return <Route {...rest} />
  }

  return null
}
