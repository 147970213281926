import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {CgSpinner} from 'react-icons/cg'
import styles from './Loader.module.scss'

const Loader = ({ className, delay, error }) => {
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), delay)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (error) {
    return <div>Error! Component failed to load</div>
  }

  if (showLoader) {
    return (
      <div className={classNames(styles.Loader, className)}>
        <CgSpinner />
      </div>
    )
  }

  return null
}

Loader.defaultProps = {
  delay: 150
}

export default Loader
