import axios from 'axios'
import url from 'url'
import { ApiError } from './errors'
import store from 'config/store'

// axios.defaults.headers.common['Accept'] = 'application/json';

const getUrl = (path, version) => url.parse(path, true).host
  ? decodeURIComponent(path)
  : `${process.env.RAZZLE_API_URI || 'http://127.0.0.1:8080'}${decodeURIComponent(`/${version}${path}`)}`

const executeApiCall = (method, path, data, params, version) => {
  const endpoint = getUrl(path, version)

  return axios(endpoint, {
    method: method,
    data,
    paramsSerializer: (params) => Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&"),
    withCredentials: true,
    ...params,
  })
  .then(response => response.data)
  .catch(error => ApiError.handleError(error))
}

const api = (version) => ({
  delete: (path, params = {}) => executeApiCall('DELETE', path, null, params, version),
  get: (path, params = {}) => executeApiCall('GET', path, null, params, version),
  post: (path, data, params = {}) => executeApiCall('POST', path, data, params, version),
  put: (path, data, params = {}) => executeApiCall('PUT', path, data, params, version),
})

const apiV1 = api('v1')
const apiV2 = api('v2')

export default apiV1

export {
  apiV1,
  apiV2,
}
