import { call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import api from 'services/api'
import {
  FAILED_GET_SPORT,
  FAILED_GET_SPORT_EVENT_TEMPLATES,
  FAILED_GET_SPORTS,
  RECEIVE_GET_SPORT,
  RECEIVE_GET_SPORT_EVENT_TEMPLATES,
  RECEIVE_GET_SPORTS,
  REQUEST_GET_SPORT,
  REQUEST_GET_SPORT_EVENT_TEMPLATES,
  REQUEST_GET_SPORTS,
} from 'redux/actions/sport'

function* getSport(action) {
  try {
    const { data } = yield call(api.get, `/sport/${action.id}`);

    yield put({ type: RECEIVE_GET_SPORT, data })
  } catch (error) {
    yield put({ type: FAILED_GET_SPORT })
  }
}

function* getSportEventTemplates(action) {
  try {
    const { data } = yield call(api.get, `/sport/${action.sportSlug}/event`);

    yield put({ type: RECEIVE_GET_SPORT_EVENT_TEMPLATES, data })
  } catch(error) {
    yield put({ type: FAILED_GET_SPORT_EVENT_TEMPLATES })
  }
}

function* getSports() {
  try {
    const { data } = yield call(api.get, `/sport`);

    yield put({ type: RECEIVE_GET_SPORTS, data })
  } catch (error) {
    yield put({ type: FAILED_GET_SPORTS })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_SPORT, getSport)
  yield takeLatest(REQUEST_GET_SPORT_EVENT_TEMPLATES, getSportEventTemplates)
  yield takeLatest(REQUEST_GET_SPORTS, getSports)
}
