import {
  FAILED_USER_AUTH_STATUS,
  FAILED_LOGIN_USER,
  RECEIVE_GET_USER,
  RECEIVE_GET_USERS,
  RECEIVE_USER_AUTH_STATUS,
  RECEIVE_LOGIN_USER,
  RECEIVE_PERSIST_USER,
  REQUEST_USER_AUTH_STATUS,
  REQUEST_LOGIN_USER,
} from 'redux/actions/user'
import {RESET_STATE} from 'redux/actions/global'
import {supportedRoles} from 'config/user'

const initialState = {
  id: null,
  role: null,
  authenticated: false,
  checked: false,
  meta: {},
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FAILED_USER_AUTH_STATUS:
    case FAILED_LOGIN_USER:
      return {
        ...state,
        authenticated: false,
        checked: true
      }
    case RECEIVE_GET_USER:
      return {
        ...state,
        form: {
          data: {
            ...state.form.data,
            ...action.data,
            role: action.data?.roles ? action.data.roles[0]?.name : null
          },
          fetching: false,
        }
      }
    case RECEIVE_GET_USERS:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RECEIVE_LOGIN_USER:
      return {
        ...state,
        authenticated: true,
        checked: true,
      }
    case RECEIVE_USER_AUTH_STATUS:
      return {
        ...state,
        id: action.id,
        authenticated: supportedRoles.includes(action.role),
        checked: true,
        role: action.role,
        meta: action.meta,
      }
    case RECEIVE_PERSIST_USER:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.data,
          },
        }
      }
    case RESET_STATE:
      if (action.state === 'user') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    case REQUEST_USER_AUTH_STATUS:
    case REQUEST_LOGIN_USER:
    default:
      return state
  }
}
